/* Provide sufficient contrast against white background */

* {
  margin: 0;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.leaflet-container, #map{
  width: 100%;
  height: 100vh;
}

.scheme-label {
  font-size: large;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  color:#565655;
  stroke: #746855;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.MuiSelect-select:focus {
  background:none !important;
}

